import { registerApplication, start } from "single-spa";
import * as isActive from "./activity-functions";


registerApplication(
  "@divihn/login",
  () => System.import("@divihn/login"),
  isActive.login,
  {
    domElement: document.getElementById("login"),
  }
);
registerApplication(
  "@divihn/client",
  () => System.import("@divihn/client"),
  isActive.client,
  { domElement: document.getElementById("client") }
);
registerApplication(
  "@divihn/consultant",
  () => System.import("@divihn/consultant"),
  isActive.consultant,
  { domElement: document.getElementById("consultant") }
);

registerApplication(
  "@divihn/flexadmin",
  () => System.import("@divihn/flexadmin"),
  isActive.flexadmin,
  { domElement: document.getElementById("flexadmin") }
);

registerApplication(
  "@divihn/flexowner",
  () => System.import("@divihn/flexowner"),
  isActive.flexowner,
  { domElement: document.getElementById("flexowner") }
);

start();
