export function prefix(location, ...prefixes) {
  return prefixes.some(
    (prefix) => location.href.indexOf(`${location.origin}/${prefix}`) !== -1
  );
}

export function nav() {
  // The nav is always active
  return true;
}

export function page1(location) {
  return prefix(location, "page1");
}

export function page2(location) {
  return prefix(location, "page2");
}

export function login(location) {
  return prefix(location, "", "signout", "xauth", "forgot-password", "signin","congratulationmsg", "expiry-link", "lets-connect", "somethingwrong");
}
export function client(location) {
  return prefix(location, "client");
}

export function consultant(location) {
  return prefix(location, "consultant");
}

export function flexadmin(location) {
  return prefix(location, "flexadmin");
}

export function flexowner(location) {
  return prefix(location, "flexowner");
}
